import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';
// import loadable from "@loadable/component"
// import "intro.js/introjs.css"

// const Steps = loadable.lib(() => import("intro.js-react"))

export default class EventFlyerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          title: "Event Flyer",
          element: "#form",
          intro:
            "The first four inputs: <ul><li>- Name</li><li>- Reason</li><li>- Theme</li><li>- Topics & Info</li></ul> must be the exact wording you would like to appear on the flyer",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          title: "Event Details",
          element: ".eventdetails",
          intro:
            "For this section, also do supply any venue details or the specific entertainment you would like featured",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          title: "Speakers",
          element: "#selectfiles",
          intro: "Don't forget to add photographs of your speakers",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro: "This allows you to add any inspiration or examples.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "If you are waiting for pre-approval then you can save what you have done so far and come back to it later. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }

  // componentDidMount() {
  //   this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  // }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>

        <FormMaker
          id={`${"selectfiles"} ${"form"}`}
          eventdetails="eventdetails"
          submitLater="submitLater"
          fileupload="fileupload"
          formId="602d90c0ba5f15001bb52fd3"
          formType="event-promotion-flyers"
        />
      </div>
    )
  }
}
